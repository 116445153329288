import "./App.css";
import React, { useState } from "react";

import { Route, Switch } from "react-router-dom";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import Home from "./components/home_page/Home";
import Products from "./components/products_page/Products";
import Product from "./components/product_page/Product";
import FAQ from "./components/FAQ_page/FAQ";
import ContactUs from "./components/contactUs_page/ContactUs";
import UserCart from "./components/userCart_page/UserCart";
import TermsConditions from "./components/termsCondition_page/TermsConditions";
import Admin from "./components/admin_page/Admin";
import ModifyProducts from "./components/modifyProducts_page/ModifyProducts";
import ModifyFAQs from "./components/ModifyFAQs_page/ModifyFAQs";
import Form from "./components/form/Form";
import FAQ_Form from "./components/FAQ_form/FAQ_form";

import NotFound from "./NotFound";

function App() {
  //const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [recentlyViewed, setRecentlyViewed] = useState(
    JSON.parse(localStorage.getItem("recentlyViewedInLocalStorage")) || []
  );
  const viewedProduct = (product) => {
    let allRecentlyViewed = [...recentlyViewed];
    let productInArray = false;
    if (recentlyViewed.some((e) => e.id === product.id)) {
      productInArray = true;
    }
    if (!productInArray) {
      if (allRecentlyViewed.length > 7) {
        allRecentlyViewed.pop();
      }
      allRecentlyViewed.unshift(product);
      localStorage.setItem(
        "recentlyViewedInLocalStorage",
        JSON.stringify(allRecentlyViewed)
      );
      setRecentlyViewed(allRecentlyViewed);
    }
  };
  const [cartProducts, setCartProducts] = useState(
    JSON.parse(localStorage.getItem("cartProductsInLocalStorage")) || []
  );
  const addToCart = (product) => {
    let allCartProducts = [...cartProducts];
    let productInCart = false;
    if (cartProducts.some((e) => e.id === product.id)) {
      productInCart = true;
    }

    if (!productInCart) allCartProducts.push(product);
    localStorage.setItem(
      "cartProductsInLocalStorage",
      JSON.stringify(allCartProducts)
    );
    setCartProducts(allCartProducts);
  };
  const removeFromRecentlyViewed = (index) => {
    if (index === -1) {
      localStorage.removeItem("recentlyViewedInLocalStorage");
      setRecentlyViewed([]);
    } else {
      let allRecentlyViewed = [...recentlyViewed];
      allRecentlyViewed.splice(index, 1);
      localStorage.setItem(
        "recentlyViewedInLocalStorage",
        JSON.stringify(allRecentlyViewed)
      );
      setRecentlyViewed(allRecentlyViewed);
    }
  };
  const removeFromCart = (index) => {
    if (index === -1) {
      localStorage.removeItem("cartProductsInLocalStorage");
      setCartProducts([]);
    } else {
      let allCartProducts = [...cartProducts];
      allCartProducts.splice(index, 1);
      localStorage.setItem(
        "cartProductsInLocalStorage",
        JSON.stringify(allCartProducts)
      );
      setCartProducts(allCartProducts);
    }
  };

  return (
    <div id="app-component-div">
      <AppHeader
        recentlyViewed={recentlyViewed}
        removeFromRecentlyViewed={removeFromRecentlyViewed}
        productsInCart={cartProducts.length}
      />

      <Switch>
        <Route exact path="/" render={() => <Home />} />
        <Route exact path="/Products" render={() => <Products />} />
        <Route
          path="/Products/:field1/:filter1/:field2/:filter2"
          render={(props) => (
            <Products
              filter1={props.match.params.filter1}
              field1={props.match.params.field1}
              filter2={props.match.params.filter2}
              field2={props.match.params.field2}
              addToCart={addToCart}
            />
          )}
        />
        {/* <Route exact path='/Admin/Petro/ModifyFAQs' render={() => <ModifyFAQs />} />
				<Route
					path='/Admin/Petro/ModifyFAQ/:id'
					// eslint-disable-next-line react/jsx-pascal-case
					render={(props) => <FAQ_Form id={props.match.params.id} />}
				/>
				<Route exact path='/Admin/Petro/AddFAQ' render={() => <FAQ_Form />} />

				<Route exact path='/FAQ' render={() => <FAQ />} /> */}
        <Route exact path="/ContactUs" render={() => <ContactUs />} />
        <Route
          exact
          path="/UserCart"
          render={() => (
            <UserCart
              cartProducts={cartProducts}
              removeFromCart={removeFromCart}
            />
          )}
        />
        {/* <Route
          exact
          path="/TermsConditions"
          render={() => <TermsConditions />}
        /> */}
        <Route
          path="/Product/:id"
          render={(props) => (
            <Product
              id={props.match.params.id}
              viewedProduct={viewedProduct}
              addToCart={addToCart}
            />
          )}
        />
        {/* <Route exact path="/Admin/Petro" render={() => <Admin />} />
        <Route
          exact
          path="/Admin/Petro/ModifyProducts"
          render={() => <ModifyProducts />}
        />
        <Route
          path="/Admin/Petro/ModifyProduct/:id"
          render={(props) => <Form id={props.match.params.id} />}
        /> */}

        <Route render={() => <NotFound />} />
      </Switch>
      <AppFooter />
    </div>
  );
}

export default App;
