import React from "react";
import "./AppFooter.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function AppFooter() {
  return (
    <div id="app-footer">
      <Container>
        <div id="app-footer-in-container">
          <div id="footer-first-col">
            <Link className="btn-footer" to={"/ContactUs"}>
              Contact Us
            </Link>
            <span>Phone: 587-679-3222</span>
            <span>
              <span className="footer-span-mobile-disable">Email:</span>
              <Link className="btn-footer" to={"/ContactUs"}>
                foodpackages@yahoo.com
              </Link>
            </span>
          </div>
          {/* <Col>
						<h4>Social Media:</h4>
						<a className='btn-footer' href={'https://www.facebook.com/'}>
							Facebook{' '}
						</a>
						<a className='btn-footer' href={'https://www.twitter.com/'}>
							twitter{' '}
						</a>
						<a className='btn-footer' href={'https://www.instagram.com/'}>
							Instagram
						</a>
					</Col> */}

          {/* <Link className="btn-footer" to={"/FAQ"}>
            FAQ
          </Link> */}

          <div>
            {/* <Link className='btn-footer' to={'/TermsConditions'}>
							Terms and Cond<span className='footer-span-mobile-disable'>itions</span>
						</Link> */}
            <p>Food Link Delivery LTD &copy; {new Date().getFullYear()} </p>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AppFooter;
