import React, { useState, useEffect } from "react";
import Loading from "../loading_page/Loading";

import "./Product.css";

import { Container, Carousel, Button, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

import { Swipeable } from "react-swipeable";

function Product(props) {
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://petro-store-server.herokuapp.com/api/products/${props.id}`)
      //fetch(`http://127.0.0.1:8080/api/products/${props.id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setProduct(data);
          if (data._id) {
            let viewedProduct = {};
            viewedProduct.image = data.images[0];
            console.log(`data.images[0]: ${data.images[0]}`);
            viewedProduct.catNumber = data.catNumber;
            viewedProduct.name = data.name;
            viewedProduct.id = data._id;
            props.viewedProduct(viewedProduct);
          }
        }
        setLoading(false);
      });
  }, [props.id]);

  const AddToCart = () => {
    let tempProduct = {};
    tempProduct.image = product.images[0];
    tempProduct.id = product._id;
    tempProduct.name = product.name;
    tempProduct.material = product.material;
    tempProduct.SKU = product.catNumber;
    tempProduct.quantity = 1;
    tempProduct.message = "";

    props.addToCart(tempProduct);
    document.getElementById("productPage-goToCart-button").style.display =
      "block";
  };

  const [show, setShow] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(false);

  const handleShow = (index) => {
    setModalImageIndex(index);
    setShow(true);
  };

  const handleSwipe = ({ dir }) => {
    if (dir === "Right") {
      console.log("right");
      if (modalImageIndex === product.images.length - 1) {
        setModalImageIndex(0);
      } else {
        setModalImageIndex(modalImageIndex + 1);
      }
    }
    if (dir === "Left") {
      console.log("right");
      if (modalImageIndex === product.images.length - 1) {
        setModalImageIndex(0);
      } else {
        setModalImageIndex(modalImageIndex + 1);
      }
    }
  };

  if (loading) {
    return <Loading />; // NOTE: This can be changed to render a <Loading /> Component for a better user experience
  } else {
    if (product._id) {
      return (
        <div id="product-page">
          <hr></hr>
          <Container>
            <Modal show={show} onHide={() => setShow(false)} centered size="xl">
              <Modal.Header closeButton>
                <Modal.Title>{product.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <Swipeable onSwiped={handleSwipe}> */}
                <img
                  style={{ width: "100%", cursor: "pointer" }}
                  src={`${process.env.PUBLIC_URL}/assets/products/SKU ${product.catNumber}/${product.images[modalImageIndex]}`}
                  alt="QQQ"
                  onClick={(event) => {
                    if (modalImageIndex === product.images.length - 1) {
                      setModalImageIndex(0);
                    } else {
                      setModalImageIndex(modalImageIndex + 1);
                    }
                  }}
                />
                {/* </Swipeable> */}
                <hr></hr>
                {product.images.map((image, index) => (
                  <img
                    className="product-page-modal-small-images"
                    onClick={() => {
                      handleShow(index);
                    }}
                    src={`${process.env.PUBLIC_URL}/assets/products/SKU ${product.catNumber}/${image}`}
                    alt="QQQ"
                  />
                ))}
              </Modal.Body>
            </Modal>

            <div id="product-page-in-container">
              <center>
                <h1>{product.name}</h1>
              </center>
              {/* <h1>Product: {product._id}</h1> */}
              {/* <h1>Product Type: {product.productType}</h1> */}
              <div id="product-img-info">
                <Carousel id="product-img-carousel">
                  {product.images.map((image, index) => (
                    <Carousel.Item key={index} className="product-carousel-img">
                      <img
                        onClick={() => {
                          handleShow(index);
                        }}
                        src={`${process.env.PUBLIC_URL}/assets/products/SKU ${product.catNumber}/${image}`}
                        alt="QQQ"
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>

                <div id="product-info">
                  <p>{product.shortDescription}</p>
                  <p>
                    <b>Material:</b>{" "}
                    <Link
                      className="product-category"
                      to={`/Products/material/${product.material}/All/All`}
                    >
                      {product.material}
                    </Link>
                  </p>
                  <p>
                    <b>SKU:</b> {product.catNumber}
                  </p>
                  {product.volume_ml || product.volume_oz ? (
                    <p>
                      <b>Volume:</b> {product.volume_oz} oz/{product.volume_ml}{" "}
                      ml
                    </p>
                  ) : (
                    ""
                  )}
                  <p>
                    <b>Categories:</b>{" "}
                    {product.categories.map((category, index) => (
                      <Link
                        className="product-category"
                        key={index}
                        to={`/Products/categories/${category}/All/All`}
                      >
                        {category}{" "}
                      </Link>
                    ))}
                  </p>
                  <p>
                    <b>Tags:</b>{" "}
                    {product.tags.map((tag, index) => (
                      <Link
                        className="product-tag"
                        key={index}
                        to={`/Products/tags/${tag}/All/All`}
                      >
                        {tag}
                      </Link>
                    ))}
                  </p>
                  <p>
                    <b>SKU:</b> {product.catNumber}
                  </p>
                  <Button
                    small
                    id="productPage-addToEnquiry-button"
                    onClick={AddToCart}
                    variant="success"
                  >
                    Add to Enquiry
                  </Button>
                  <Button
                    id="productPage-goToCart-button"
                    small
                    onClick={() => {
                      props.history.push(`/UserCart`);
                    }}
                    variant="success"
                  >
                    Go to cart
                  </Button>
                </div>
              </div>
              <br></br>
              <h2>Description:</h2>
              <p>{product.description}</p>
            </div>
          </Container>
          <hr></hr>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Unable to find product</h1>
          <p>id: {props.id}</p>
        </div>
      );
    }
  }
}

export default withRouter(Product);
