import React, { useState, useEffect } from 'react';
import './ModifyProducts.css';
import Loading from '../loading_page/Loading';
import { Container, Table, Pagination } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

function ModifyProducts(props) {
	const [products, setProducts] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(33);

	const getData = (page, perPage) => {
		return new Promise((res, rej) => {
			//console.log(`page33: ${page}`);
			//console.log(`perPAge34: ${perPage}`);
			fetch(
				`https://petro-store-server.herokuapp.com/api/products?page=${page}&perPage=${perPage}&field1=${'All'}&filter1=${'All'}&field2=${'All'}&filter2=${'All'}`
			)
				.then((res) => res.json())
				.then((data) => {
					if (data.data.length) {
						//console.log(JSON.stringify(data));

						res(data.data.reverse());
					} else {
						res(false);
					}
				});
		});
	};

	//After the component has been "mounted", we must Call our "getData(page)..."
	useEffect(() => {
		getData(currentPage, perPage).then((data) => {
			if (data) setProducts(data);
		});
	}, [perPage]);

	const previousPage = () => {
		if (currentPage > 1) {
			getData(currentPage - 1, perPage).then((data) => {
				if (data) {
					setProducts(data);
					setCurrentPage(currentPage - 1);
				}
			});
		}
	};

	const nextPage = () => {
		getData(currentPage + 1, perPage).then((data) => {
			if (data) {
				setProducts(data);
				setCurrentPage(currentPage + 1);
			}
		});
	};

	const deleteProduct = (id) => {
		return new Promise((res, rej) => {
			//console.log(`id: 88 ${id}`);
			fetch(`https://petro-store-server.herokuapp.com/api/product/${id}`, { method: 'DELETE' })
				.then((res) => res.json())
				.then((msg) => {
					if (msg) console.log(msg);
					getData(currentPage, perPage).then((data) => {
						if (data) setProducts(data);
					});
				});
		});
	};

	if (products.length > 0) {
		return (
			<div className='products-page'>
				<center>
					<h1>AS YOU WISH ADMIN PETRO))</h1>
					<h3>Let's modify/delete some products:)...</h3>
					<Link to='/Admin/Petro'>here if you wish to add new product</Link>
				</center>
				<Container>
					<center>
						<h3>Total: {products.length} product(s) on this page</h3>
						<h6> per page: {perPage}</h6>
					</center>

					<Table hover>
						<thead>
							<tr>
								<th>Main Picture</th>
								<th>All Images</th>

								<th>Name</th>
								<th>SKU</th>
								<th>Created</th>
								<th>Updated</th>

								<th>Short Description</th>
								<th>Material</th>

								<th>Volume</th>
								<th>Product Type</th>
								<th>categories</th>

								<th>tags</th>
								{/* <th>lids</th> */}
							</tr>
						</thead>
						<tbody>
							{products.map((product) => (
								<tr key={product._id}>
									<td id='main-picture'>
										<div>
											<img
												src={`${process.env.PUBLIC_URL}/assets/products/SKU ${product.catNumber}/${product.images[0]}`}
												alt='QQQ'
											/>
											<Link
												className='btn btn-default'
												id='modify-button'
												to={`/Admin/Petro/ModifyProduct/${product._id}`}
											>
												<b>Modify</b>
											</Link>
											<Link
												className='btn btn-default'
												id='delete-button'
												onClick={() => {
													deleteProduct(product._id);
												}}
											>
												<b>Delete</b>
											</Link>
										</div>
									</td>

									<td id='all-images'>
										{product.images.map((image) => (
											<img
												src={`${process.env.PUBLIC_URL}/assets/products/SKU ${product.catNumber}/${image}`}
												alt='QQQ'
											/>
										))}
									</td>

									<td>{product.name}</td>
									<td>{product.catNumber}</td>
									<td>
										{product.createdAt.substring(5, 10) + ' ' + product.createdAt.substring(11, 19)}
									</td>
									<td>
										{product.updatedAt.substring(5, 10) + ' ' + product.updatedAt.substring(11, 19)}
									</td>

									<td>{product.shortDescription}</td>
									<td>{product.material}</td>

									<td>
										{product.volume_oz} oz/{product.volume_ml} ml
									</td>
									<td>{product.productType}</td>

									<td>
										{product.categories.map((category) => (
											<span>
												<b>/ </b>
												{category}
											</span>
										))}
									</td>

									<td>
										{product.tags.map((tag) => (
											<span>
												<b>/ </b>
												{tag}
											</span>
										))}
									</td>
									{/* <td>
										{product.lids.map((lid) => (
											<span>
												<b>/ </b>
												{lid}
											</span>
										))}
									</td> */}
								</tr>
							))}
						</tbody>
					</Table>

					<Pagination>
						<Pagination.Prev onClick={previousPage} />
						<Pagination.Item>{currentPage}</Pagination.Item>
						<Pagination.Next onClick={nextPage} />
					</Pagination>
				</Container>
			</div>
		);
	} else {
		return <Loading />; // NOTE: This can be changed to render a <Loading /> Component for a better user experience
	}
}

export default withRouter(ModifyProducts);
