import React from 'react';
import './Loading.css';
import Spinner from 'react-bootstrap/Spinner';
import { Container } from 'react-bootstrap';

function Loading() {
	return (
		<div id='loading-page'>
			<Container>
				<h1>Please wait </h1>
				<Spinner animation='border' role='status'>
					<span className='sr-only'>Loading...</span>
				</Spinner>
				<p>
					Working on your request...waiting for respond from heroku...usually it takes around 5-10
					sec, please wait... if longer call 911 ASAP
				</p>
			</Container>
		</div>
	);
}

export default Loading;
