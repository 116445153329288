import React from "react";
import Container from "react-bootstrap/Container";
function NotFound() {
  return (
    <div>
      <Container>
        <h1>Route NOT FOUND</h1>
      </Container>
    </div>
  );
}

export default NotFound;
