import React, { useState, useEffect } from 'react';
import Loading from '../loading_page/Loading';

import { Container, Modal, Button } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';

import './FAQ_form.css';

function FAQ_Form(props) {
	const [FAQData, setFAQData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	const [modalMessage, setModalMessage] = useState(false);

	const handleClose = () => {
		setShow(false);
		//props.history.goBack(); redirect to previous page
	};
	const handleShow = (msg) => {
		console.log(`modalMessage: ${msg}`);
		setModalMessage(msg);
		setShow(true);
	};
	useEffect(() => {
		if (props.id) {
			fetch(`https://petro-store-server.herokuapp.com/api/FAQ/${props.id}`)
				.then((res) => res.json())
				.then((data) => {
					if (data) {
						setFAQData(data);
					}
					setLoading(false);
				});
		} else {
			setFAQData({
				question: '',
				answer: '',
			});
			setLoading(false);
		}
	}, []);

	const handleChange = (e) => {
		let target = e.target; // the element that initiated the event
		let value = target.value; // its value
		let name = target.name; // its name

		let newFAQData = { ...FAQData }; // preform a "shallow" clone of FAQData
		newFAQData[name] = value; // update the associated property for the control

		setFAQData(newFAQData); // set the new FAQ data
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (props.id) {
			fetch(`https://petro-store-server.herokuapp.com/api/FAQ/${props.id}`, {
				method: 'PUT',
				body: JSON.stringify({ FAQData }),
				headers: { 'Content-Type': 'application/json' },
			})
				// fetch(`http://127.0.0.1:8080/api/FAQ/${props.id}`, {
				// 	method: 'PUT',
				// 	body: JSON.stringify({ FAQData }),
				// 	headers: { 'Content-Type': 'application/json' },
				// })
				.then((res) => res.json())
				.then((msg) => {
					handleShow(msg);
					if (msg) {
						console.log(msg);
					}
				});
		} else {
			fetch(`https://petro-store-server.herokuapp.com/api/FAQ`, {
				method: 'POST',
				body: JSON.stringify({ FAQData }),
				headers: { 'Content-Type': 'application/json' },
			})
				// fetch(`http://127.0.0.1:8080/api/FAQ`, {
				// 	method: 'POST',
				// 	body: JSON.stringify({ FAQData }),
				// 	headers: { 'Content-Type': 'application/json' },
				// })
				.then((res) => res.json())
				.then((msg) => {
					handleShow(msg);
					if (msg) console.log(msg);
				});
		}
	};

	if (loading) {
		return <Loading></Loading>; // render nothing until the form data is loaded
	} else {
		return (
			<div className='form-page'>
				<Container>
					<Modal show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>Report:</Modal.Title>
						</Modal.Header>
						<Modal.Body>{modalMessage}</Modal.Body>
						<Modal.Footer>
							<Button variant='secondary' onClick={handleClose}>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
					<center>
						{' '}
						<Link to='/Admin/Petro/ModifyFAQs'> List of FAQs to Modify/Delete</Link>
					</center>

					<form onSubmit={handleSubmit}>
						<label htmlFor='FAQ-question'>Question: </label>
						<input
							id='FAQ-question'
							name='question'
							type='text'
							required
							value={FAQData.question}
							onChange={handleChange}
						/>

						<label htmlFor='FAQ-answer'>Answer: </label>
						<textarea
							id='FAQ-answer'
							name='answer'
							type='text'
							value={FAQData.answer}
							onChange={handleChange}
						></textarea>
						<div></div>
						<input
							id='sbm-btn'
							type='submit'
							name='submit'
							value={props.id ? 'Modify Question/Answer' : 'Add Question/Answer'}
						/>
					</form>
				</Container>
			</div>
		);
	}
}

export default withRouter(FAQ_Form);
