import React, { useState, useEffect } from "react";
import "./UserCart.css";
import { withRouter, Link } from "react-router-dom";

import { Container, Modal, Table, Button } from "react-bootstrap";

function UserCart(props) {
  const [cartProducts, setCartProducts] = useState(null);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userDataInLocalStorage")) || null
  );
  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const handleClose = () => {
    setShow(false);
    props.history.push("/"); //redirect to previous page
  };
  const handleShow = (msg) => {
    console.log(`modalMessage: ${msg}`);
    setModalMessage(msg);
    setShow(true);
  };

  useEffect(() => {
    if (props.cartProducts.length) {
      setCartProducts(props.cartProducts);
      if (!userData) {
        setUserData({
          name: "",
          email: "",
          phone: "",
          phoned: true,
          subject: "",
          message: "",
        });
      }
    } else {
      setCartProducts(null);
    }
  }, [props.cartProducts.length]);

  const handleProductsChange = (e) => {
    let target = e.target; // the element that initiated the event
    let value = target.value; // its value
    let name = target.name; // its name

    let newCartProducts = [...cartProducts]; // preform a "shallow" clone of productData
    newCartProducts.forEach((product) => {
      if (product.id === name.substring(9)) {
        //quantity-/textarea- = 9 char
        if (name === `quantity-${product.id}`) {
          product.quantity = value;
          return;
        } else if (name === `textarea-${product.id}`) {
          product.message = value;
          return;
        }
      }
    });

    setCartProducts(newCartProducts); // set the new product data
  };

  const handleChange = (e) => {
    let target = e.target; // the element that initiated the event
    let value = target.value; // its value
    let name = target.name; // its name

    let newUserData = { ...userData }; // preform a "shallow" clone of productData
    newUserData[name] = value; // update the associated property for the control
    localStorage.setItem("userDataInLocalStorage", JSON.stringify(newUserData));
    setUserData(newUserData); // set the new product data
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let enquire = {};
    enquire.products = cartProducts;
    enquire.user = userData;

    // fetch(`http://127.0.0.1:8080/api/enquire`, {
    fetch(`https://petro-store-server.herokuapp.com/api/enquire`, {
      method: "POST",
      body: JSON.stringify({ enquire }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((msg) => {
        if (msg === "email was sent") {
          handleShow(msg);
          console.log(msg);
          setUserData({
            name: "",
            email: "",
            phone: "",
            phoned: true,
            subject: "",
            message: "",
          });
          props.removeFromCart(-1); //setCartProducts(null);
        } else {
          handleShow(`Unexpected error, try again later or contact us at 
					info.foodpackages@gmail.com`);
        }
      })
      .catch((e) => {
        console.log(`server.js-266-- ${e}`);
      });
  };

  return (
    <div id="userCart-page">
      <hr></hr>
      <Container>
        <div id="userCart-page-in-container">
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Report:</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {cartProducts ? (
            <div>
              <Table hover className="cart-product-list-table">
                <thead>
                  <tr>
                    <th> </th>
                    <th className="cart-page-data-mobile-disable"> </th>
                    <th>
                      Prod
                      <span className="cart-page-data-mobile-disable">uct</span>
                    </th>
                    <th className="cart-page-data-mobile-disable">Material</th>
                    <th className="cart-page-data-mobile-disable">SKU</th>
                    <th>
                      Q
                      <span className="cart-page-data-mobile-disable">
                        uantity
                      </span>
                    </th>
                    <th id="cart-page-header-message">Message</th>
                  </tr>
                </thead>
                <tbody>
                  {cartProducts.map((product, index) => (
                    <tr key={product.id}>
                      <td className="user-crt-product-img-td">
                        <Button
                          small
                          className="cart-remove-product-button"
                          onClick={() => {
                            props.removeFromCart(index);
                          }}
                          variant="danger"
                          size="sm"
                        >
                          X
                        </Button>
                      </td>
                      <td className="user-crt-product-img-td">
                        <Link
                          className="cart-page-link "
                          to={`/Product/${product.id}`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/products/SKU ${product.SKU}/${product.image}`}
                            alt="Coming soon"
                          />
                        </Link>
                      </td>
                      <td className="cart-page-data-mobile-disable">
                        <Link
                          className="cart-page-link"
                          to={`/Product/${product.id}`}
                        >
                          {product.name}
                        </Link>
                      </td>
                      <td className="cart-page-data-mobile-disable">
                        <Link
                          className="cart-page-link"
                          to={`/Products/material/${product.material}/All/All`}
                        >
                          {product.material}
                        </Link>
                      </td>
                      <td className="cart-page-data-mobile-disable">
                        <Link
                          className="cart-page-link"
                          to={`/Product/${product.id}`}
                        >
                          {product.SKU}
                        </Link>
                      </td>
                      <td className="cart-quantity-input-td">
                        <input
                          id="quantity"
                          name={`quantity-${product.id}`}
                          type="number"
                          value={product.quantity}
                          min="1"
                          onChange={handleProductsChange}
                        />
                      </td>
                      <textarea
                        id="cart-product-msg-textarea"
                        name={`textarea-${product.id}`}
                        value={product.message}
                        onChange={handleProductsChange}
                      ></textarea>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <form onSubmit={handleSubmit}>
                <label htmlFor="contact-name">Name*: </label>
                <input
                  id="contact-name"
                  name="name"
                  type="text"
                  required
                  value={userData.name}
                  onChange={handleChange}
                />

                <label htmlFor="contact-email">Email*: </label>
                <input
                  id="contact-email"
                  name="email"
                  type="email"
                  required
                  value={userData.email}
                  onChange={handleChange}
                />
                <label for="contact-phone">Phone Number: </label>
                <input
                  id="contact-phone"
                  name="phone"
                  type="text"
                  pattern="^([0-9]{3})[-]?[ ]?([0-9]{3})[-]?[ ]?([0-9]{4})$"
                  placeholder="111 222 3333"
                  value={userData.phone}
                  onChange={handleChange}
                />

                <label htmlFor="contact-subject">Subject*: </label>
                <input
                  id="contact-subject"
                  name="subject"
                  type="text"
                  required
                  value={userData.subject}
                  onChange={handleChange}
                />
                <label for="phoned">Message*: </label>

                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  placeholder="Your message here..."
                  value={userData.message}
                  onChange={handleChange}
                  required
                ></textarea>
                <div></div>
                <div>
                  <input
                    id="submit-enquiry-button"
                    type="submit"
                    name="submit"
                    value="Submit Enquiry"
                  />
                </div>
              </form>
            </div>
          ) : (
            <h3>Cart is empty ):</h3>
          )}
        </div>
      </Container>
      <hr></hr>
    </div>
  );
}

export default withRouter(UserCart);
