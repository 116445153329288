import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import { Container, Modal, Button } from "react-bootstrap";
// import { Link, Switch, Redirect, Route } from 'react-router-dom';

function ContactUs() {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userDataInLocalStorage")) || null
  );
  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const handleClose = () => {
    setShow(false);
    //props.history.push('/'); //redirect to previous page
  };
  const handleShow = (msg) => {
    console.log(`modalMessage: ${msg}`);
    setModalMessage(msg);
    setShow(true);
  };

  useEffect(() => {
    if (!userData) {
      setUserData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
    }
  }, []);
  const handleChange = (e) => {
    let target = e.target; // the element that initiated the event
    let value = target.value; // its value
    let name = target.name; // its name

    let newUserData = { ...userData }; // preform a "shallow" clone of productData
    newUserData[name] = value; // update the associated property for the control
    localStorage.setItem("userDataInLocalStorage", JSON.stringify(newUserData));
    setUserData(newUserData); // set the new product data
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let enquire = {};
    enquire.user = userData;

    // fetch(`http://127.0.0.1:8080/api/enquire`, {
    //fetch(`http://127.0.0.1:8080/api/ssh`, {
    fetch(`https://petro-store-server.herokuapp.com/api/enquire`, {
      method: "POST",
      body: JSON.stringify({ enquire }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((msg) => {
        if (msg === "email was sent") {
          handleShow(msg);
          console.log(msg);
          localStorage.clear();
          setUserData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        } else {
          handleShow(`Unexpected error, try again later or contact us at 
					foodlinkpetro@gmail.com`);
        }
      })
      .catch((e) => {
        console.log(`server.js-266-- ${e}`);
      });
  };
  if (userData) {
    return (
      <div id="contactUs-page">
        <hr></hr>
        <Container>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Report:</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <center>
            <h3 id="send-us-a-message">Send us a message</h3>
          </center>
          <div id="contactUs-page-in-container">
            <div id="contactUs-page-contact-info">
              <h4>Foodpackages Team</h4>
              <p>
                Thank you for visiting Foodpackages. Please fill the form to
                contact us. We valued every comment and inquiry. Our service
                representative will respond to your questions at our best.
              </p>
              <hr></hr>
              <h4>Contact Information:</h4>
              <p>Food Link Delivery LTD </p>
              <p>91 Viscount Dr, Red Deer,</p>
              <p>AB, Canada T4R 0G6</p>
              <p>Tel：1-587-679-3222</p>
              <p>Sales：foodpackages@yahoo.com</p>
              <p>Customer Service：info@foodpackages.ca</p>
            </div>
            <form onSubmit={handleSubmit}>
              <label htmlFor="contact-name">Your Name*: </label>
              <input
                id="contact-name"
                name="name"
                type="text"
                required
                value={userData.name}
                onChange={handleChange}
              />

              <label htmlFor="contact-email">Your Email*: </label>
              <input
                id="contact-email"
                name="email"
                type="email"
                required
                value={userData.email}
                onChange={handleChange}
              />
              <label for="contact-phone">Phone Number: </label>
              <input
                id="contact-phone"
                name="phone"
                type="text"
                pattern="^([0-9]{3})[-]?[ ]?([0-9]{3})[-]?[ ]?([0-9]{4})$"
                placeholder="111 222 3333"
                value={userData.phone}
                onChange={handleChange}
              />

              <label htmlFor="contact-subject">Subject*: </label>
              <input
                id="contact-subject"
                name="subject"
                type="text"
                required
                value={userData.subject}
                onChange={handleChange}
              />
              <label for="phoned">Message*: </label>

              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                placeholder="Your message here..."
                value={userData.message}
                onChange={handleChange}
                required
              ></textarea>
              <div></div>
              <div>
                <input
                  id="send-msg-button"
                  type="submit"
                  name="submit"
                  value="Send"
                />
              </div>
            </form>
          </div>
        </Container>
        <hr></hr>
      </div>
    );
  } else {
    return <Container>Call 911</Container>;
  }
}

export default ContactUs;
