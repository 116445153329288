import React, { useState, useEffect } from "react";
import "./Home.css";
import img1 from "./winter_500.jpg";
import img2 from "./fall_500.jpg";
import paper_img from "./Paper.jpg";
import plastic_img from "./Plastic.jpg";
import other_img from "./Other.jpg";
import beverages_to_go_img from "./Beverage-to-go.jpg";
import food_to_go_img from "./Food-to-go.jpg";
import all_top_products_img from "./All-top-products.jpg";
import all_products_img from "./All-products.jpg";

import { Container, Image, Carousel, Button, Card } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

function Home() {
  const [topProducts, setTopProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const getTopProducts = () => {
    return new Promise((res, rej) => {
      fetch(
        `https://petro-store-server.herokuapp.com/api/products?page=1&perPage=8&field1=tags&filter1=Top&field2=All&filter2=All`
      )
        // fetch(
        // 	`http://127.0.0.1:8080/api/products?page=${page}&perPage=${perPage}&field1=${props.field1}&filter1=${props.filter1}&field2=${props.field2}&filter2=${props.filter2}`
        // )
        .then((res) => res.json())
        .then((data) => {
          if (data.data.length) {
            res(data.data);
          } else {
            res(false);
          }
        });
    });
  };
  useEffect(() => {
    getTopProducts().then((data) => {
      console.log(data);
      if (data) {
        setTopProducts(data);
        setLoading(false);
      } else {
        setTopProducts(["Coming Soon"]);
        setLoading(false);
      }
    });
  }, []);

  return (
    <div id="home-page">
      <Image className="hero-img" fluid src={img1}></Image>
      {/* <Carousel id="homePage-carousel">
        <Carousel.Item interval={10000}>
          <img className="d-block w-100" src={img1} alt="First slide" />
          <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={50000}>
          <img className="d-block w-100" src={img2} alt="Second slide" />
          <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={50000}>
					<img className='d-block w-100' src={img3} alt='Third slide' />
					<Carousel.Caption>
						<h3>Third slide label</h3>
						<p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
					</Carousel.Caption>
				</Carousel.Item>
      </Carousel> */}
      <br></br>
      <Container>
        <div id="home-page-in-container">
          <center>
            <h2>Our Top Products</h2>
          </center>

          <div id="topProducts-list">
            {!loading
              ? topProducts.map((product) => (
                  <Card
                    key={product._id}
                    bg="light"
                    text="dark"
                    className="products-card"
                  >
                    <Link to={`/Product/${product._id}`}>
                      <Card.Img
                        variant="top"
                        src={`${process.env.PUBLIC_URL}/assets/products/SKU ${product.catNumber}/${product.images[0]}`}
                      />
                    </Link>
                  </Card>
                ))
              : ""}
            <Card bg="light" text="dark" className="products-card">
              <Link to={`/Products/tags/Top/All/All`}>
                <Card.Img variant="top" src={all_top_products_img} />
              </Link>
              <Card.Body>
                <Link
                  className="products-card-body-link"
                  to={`/Products/tags/Top/All/All`}
                >
                  <Card.Title>All Top Products</Card.Title>
                </Link>
              </Card.Body>
            </Card>
            <Card bg="light" text="dark" className="products-card">
              <Link to={`/Products/All/All/All/All`}>
                <Card.Img variant="top" src={all_products_img} />
              </Link>
              <Card.Body>
                <Link
                  className="products-card-body-link"
                  to={`/Products/All/All/All/All`}
                >
                  <Card.Title>All Products</Card.Title>
                </Link>
              </Card.Body>
            </Card>
          </div>
          <br></br>
          <hr></hr>
          <div id="home-page-text-categories">
            <div>
              <h3>Shop by Food Packaging Style</h3>
              <div id="home-page-packaging-style">
                <Card bg="light" text="dark" className="products-card">
                  <Link to={"/Products/material/Paper/All/All"}>
                    <Card.Img variant="top" src={paper_img} />
                  </Link>
                  <Card.Body>
                    <Link
                      className="products-card-body-link"
                      to={"/Products/material/Paper/All/All"}
                    >
                      <Card.Title>Paper</Card.Title>
                    </Link>
                  </Card.Body>
                </Card>
                <Card bg="light" text="dark" className="products-card">
                  <Link to={"/Products/material/Plastic/All/All"}>
                    <Card.Img variant="top" src={plastic_img} />
                  </Link>
                  <Card.Body>
                    <Link
                      className="products-card-body-link"
                      to={"/Products/material/Plastic/All/All"}
                    >
                      <Card.Title>Plastic</Card.Title>
                    </Link>
                  </Card.Body>
                </Card>
                <Card bg="light" text="dark" className="products-card">
                  <Link to={"/Products/material/Other/All/All"}>
                    <Card.Img variant="top" src={other_img} />
                  </Link>
                  <Card.Body>
                    <Link
                      className="products-card-body-link"
                      to={"/Products/material/Other/All/All"}
                    >
                      <Card.Title>Other</Card.Title>
                    </Link>
                  </Card.Body>
                </Card>
                <Card bg="light" text="dark" className="products-card">
                  <Link to={`/Products/All/All/All/All`}>
                    <Card.Img variant="top" src={all_products_img} />
                  </Link>
                  <Card.Body>
                    <Link
                      className="products-card-body-link"
                      to={`/Products/All/All/All/All`}
                    >
                      <Card.Title>All Products</Card.Title>
                    </Link>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div id="home-page-text">
              <center>
                <h3>why foodlink ?</h3>
              </center>

              <p>
                When ordering from us, you do not have to worry about delivery,
                because we have our own production warehouses for storing goods
                and you do not have to wait several days until the order is
                fully formed, but receive the goods the very next day after the
                order.
              </p>
              <p>
                Delivery speed, high-quality raw materials, the ability to order
                goods both wholesale and retail and a large assortment - this is
                what makes us the best choice for cooperation.
              </p>
              <p>
                We have a flexible system of discounts for regular and wholesale
                customers. Please fill out the contact us form and our managers
                will be happy to inform you about the current conditions and
                discounts.
              </p>
              <p>It's simple and convenient.</p>
            </div>
          </div>
          <hr></hr>
          <h4>Shop by Industries</h4>
          <div id="home-page-industries">
            <Card bg="light" text="dark" className="products-card">
              <Link to={`/Products/categories/Beverage To Go/All/All`}>
                <Card.Img variant="top" src={beverages_to_go_img} />
              </Link>
              <Card.Body>
                <Link
                  className="products-card-body-link"
                  to={`/Products/categories/Beverage To Go/All/All`}
                >
                  <Card.Title>Beverage To Go</Card.Title>
                </Link>
              </Card.Body>
            </Card>
            <Card bg="light" text="dark" className="products-card">
              <Link to={`/Products/categories/Food To Go/All/All`}>
                <Card.Img variant="top" src={food_to_go_img} />
              </Link>
              <Card.Body>
                <Link
                  className="products-card-body-link"
                  to={`/Products/categories/Food To Go/All/All`}
                >
                  <Card.Title>Food To Go</Card.Title>
                </Link>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
      <hr></hr>
    </div>
  );
}

export default withRouter(Home);
