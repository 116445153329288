import React, { useState, useEffect } from 'react';
import Loading from '../loading_page/Loading';

import { Container, Modal, Button } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';

import './Form.css';

function Form(props) {
	const [productData, setProductData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	const [modalMessage, setModalMessage] = useState(false);

	const handleClose = () => {
		setShow(false);
		//props.history.goBack(); redirect to previous page
	};
	const handleShow = (msg) => {
		console.log(`modalMessage: ${msg}`);
		setModalMessage(msg);
		setShow(true);
	};
	useEffect(() => {
		if (props.id) {
			fetch(`https://petro-store-server.herokuapp.com/api/products/${props.id}`)
				.then((res) => res.json())
				.then((data) => {
					if (data) {
						setProductData(data);
					}
					setLoading(false);
				});
		} else {
			setProductData({
				name: '',
				shortDescription: '',
				color: '',
				material: '',
				catNumber: '', // SKU
				description: '',
				volume_ml: 0,
				volume_oz: 0,
				productType: '',
				categories: [''],
				tags: [''],
				lids: [''], // SKU s
				images: [''], //addresses//fileNames
				additionalInfo_Title: [''],
				additionalInfo_Data: [''],
				possibleQuestions_Questions: [''],
				possibleQuestions_Answers: [''],
			});
			setLoading(false);
		}
	}, []);

	const handleChange = (e) => {
		let target = e.target; // the element that initiated the event
		let value = target.value; // its value
		let name = target.name; // its name

		let newProductData = { ...productData }; // preform a "shallow" clone of productData
		newProductData[name] = value; // update the associated property for the control
		if (name === 'volume_oz') {
			newProductData.volume_oz = (value * 1).toFixed(0);
			newProductData.volume_ml = (value * 29.5735).toFixed(0);
			//newProductData.volume_ml = Math.round(value * 2.95735) * 10;
		}
		if (name === 'volume_ml') {
			newProductData.volume_ml = (value * 1).toFixed(0);
			newProductData.volume_oz = (value * 0.033814).toFixed(0);
		}
		setProductData(newProductData); // set the new product data
	};

	const handleDataArrayChange = (idx) => (evt) => {
		let name = evt.target.name;
		console.log(`evt.target.value: ${evt.target.value}`);

		const newDataArray = productData[name].length
			? productData[name].map((data, dataIdx) => {
					if (idx !== dataIdx) {
						return data;
					} else {
						return evt.target.value;
					}
			  })
			: [evt.target.value];
		let newProductData = { ...productData };
		newProductData[name] = newDataArray;
		setProductData(newProductData);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (props.id) {
			fetch(`https://petro-store-server.herokuapp.com/api/products/${props.id}`, {
				method: 'PUT',
				body: JSON.stringify({ productData }),
				headers: { 'Content-Type': 'application/json' },
			})
				.then((res) => res.json())
				.then((msg) => {
					handleShow(msg);
					if (msg) {
						console.log(msg);
					}
				});
		} else {
			fetch(`https://petro-store-server.herokuapp.com/api/products`, {
				method: 'POST',
				body: JSON.stringify({ productData }),
				headers: { 'Content-Type': 'application/json' },
			})
				.then((res) => res.json())
				.then((msg) => {
					handleShow(msg);
					if (msg) console.log(msg);
				});
		}
	};

	const addInputField = (field) => {
		console.log('inside AddInputField');
		let index = productData[field].length;
		let newProductData = { ...productData };
		newProductData[field].push('');
		if (field === 'additionalInfo_Title') newProductData.additionalInfo_Data[index] = '';
		if (field === 'possibleQuestions_Questions')
			newProductData.possibleQuestions_Answers[index] = '';
		setProductData(newProductData);
	};

	if (loading) {
		return <Loading></Loading>; // render nothing until the form data is loaded
	} else {
		return (
			<div className='form-page'>
				<Container>
					<Modal show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>Report:</Modal.Title>
						</Modal.Header>
						<Modal.Body>{modalMessage}</Modal.Body>
						<Modal.Footer>
							<Button variant='secondary' onClick={handleClose}>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
					<center>
						{' '}
						<Link to='/Admin/Petro/ModifyProducts'> List of products to Modify/Delete</Link>
					</center>

					<form onSubmit={handleSubmit}>
						<label htmlFor='product-name'>Product Name: </label>
						<input
							id='product-name'
							name='name'
							type='text'
							required
							value={productData.name}
							onChange={handleChange}
						/>

						<label htmlFor='shortDescription'>Short Description: </label>
						<input
							id='shortDescription'
							name='shortDescription'
							type='text'
							value={productData.shortDescription}
							onChange={handleChange}
						/>

						<label htmlFor='color'>T.M.SKU: </label>
						<div id='catNum-type-mat'>
							<label htmlFor='catNumber'>SKU: </label>
							<input
								id='catNumber'
								name='catNumber'
								type='text'
								required
								value={productData.catNumber}
								onChange={handleChange}
							/>
							<label htmlFor='productType'>Type:</label>
							<select
								id='productType'
								name='productType'
								value={productData.productType}
								onChange={handleChange}
							>
								<option value='unknown'> </option>
								<option value='Cup'>Cup</option>
								<option value='Bowl'>Bowl</option>
								<option value='Box'>Box</option>
								<option value='Bucket'>Bucket</option>
								<option value='Straw'>Straw</option>
								<option value='Tray'>Tray</option>
								<option value='Lid'>Lid</option>
								<option value='Bag'>Bag</option>
								<option value='Other'>Other</option>
							</select>

							<label htmlFor='material'>Material: </label>
							<select
								id='material'
								name='material'
								value={productData.material}
								onChange={handleChange}
							>
								<option value='unknown'> </option>
								<option value='Paper'>Paper</option>
								<option value='Plastic'>Plastic</option>
								<option value='Other'>Other</option>
							</select>
						</div>
						<label htmlFor='volume'>Volume:</label>
						<div id='form-volume'>
							<label htmlFor='volume'>oz:</label>
							<input
								id='volume'
								name='volume_oz'
								type='number'
								value={productData.volume_oz}
								min='0'
								onChange={handleChange}
							/>
							<label htmlFor='volume'>ml:</label>
							<input
								id='volume'
								name='volume_ml'
								type='number'
								value={productData.volume_ml}
								min='0'
								onChange={handleChange}
							/>
							<div></div>
						</div>

						<label htmlFor='categories'>Categories:</label>
						<div id='div-categories'>
							{productData.categories.length ? (
								productData.categories.map((category, index) => (
									<input
										key={index}
										id='categories'
										name='categories'
										type='text'
										value={category}
										onChange={handleDataArrayChange(index)}
									/>
								))
							) : (
								<input
									id='categories'
									name='categories'
									type='text'
									onChange={handleDataArrayChange(0)}
								/>
							)}

							<div id='addCategories'></div>
							<div id='div-button' onClick={() => addInputField('categories')}>
								Add Category
							</div>
						</div>
						<label htmlFor='tags'>Tags:</label>
						<div>
							{productData.tags.length ? (
								productData.tags.map((tag, index) => (
									<input
										key={index}
										id='tags'
										name='tags'
										type='text'
										value={tag}
										onChange={handleDataArrayChange(index)}
									/>
								))
							) : (
								<input id='tags' name='tags' type='text' onChange={handleDataArrayChange(0)} />
							)}

							<div id='addTags'></div>
							<div id='div-button' onClick={() => addInputField('tags')}>
								Add Tag #
							</div>
						</div>
						<label htmlFor='lids'>Lids(SKU):</label>
						<div>
							{productData.lids.length ? (
								productData.lids.map((lid, index) => (
									<input
										key={index}
										id='lids'
										name='lids'
										type='text'
										value={lid}
										onChange={handleDataArrayChange(index)}
									/>
								))
							) : (
								<input id='lids' name='lids' type='text' onChange={handleDataArrayChange(0)} />
							)}

							<div id='addLids'></div>
							<div id='div-button' onClick={() => addInputField('lids')}>
								Add Lid(SKU)
							</div>
						</div>
						<label htmlFor='images'>Images:</label>
						<div>
							{productData.images.length ? (
								productData.images.map((image, index) => (
									<input
										key={index}
										id='images'
										name='images'
										type='text'
										defaultValue={image}
										onChange={handleDataArrayChange(index)}
									/>
								))
							) : (
								<input id='images' name='images' type='text' onChange={handleDataArrayChange(0)} />
							)}

							<div id='addImage'></div>
							<div id='div-button' onClick={() => addInputField('images')}>
								Add Image
							</div>
						</div>
						<label htmlFor='description'>Description: </label>
						<textarea
							name='description'
							id='description'
							placeholder='Full description here...'
							value={productData.description}
							onChange={handleChange}
						></textarea>

						<label htmlFor='div-additionalInfo'>Additional Info:</label>
						<div id='div-additionalInfo'>
							{productData.additionalInfo_Title.length ? (
								productData.additionalInfo_Title.map((title, index) => (
									<div key={index}>
										<label htmlFor='additionalInfo_Title'>Title:</label>
										<input
											id='additionalInfo_Title'
											name='additionalInfo_Title'
											type='text'
											value={title}
											onChange={handleDataArrayChange(index)}
										/>
										<label htmlFor='additionalInfo_Data'>Info:</label>
										<input
											id='additionalInfo_Data'
											name='additionalInfo_Data'
											type='text'
											value={productData.additionalInfo_Data[index]}
											onChange={handleDataArrayChange(index)}
										/>
									</div>
								))
							) : (
								<div>
									<label htmlFor='additionalInfo_Title'>Title:</label>
									<input
										id='additionalInfo_Title'
										name='additionalInfo_Title'
										type='text'
										onChange={handleDataArrayChange(0)}
									/>
									<label htmlFor='additionalInfo_Data'>Info:</label>
									<input
										id='additionalInfo_Data'
										name='additionalInfo_Data'
										type='text'
										onChange={handleDataArrayChange(0)}
									/>
								</div>
							)}

							<div id='addInfo'></div>
							<div id='div-button' onClick={() => addInputField('additionalInfo_Title')}>
								Add_Info
							</div>
						</div>

						<label htmlFor='div-possibleQuestions'>Possible Questions:</label>
						<div id='div-possibleQuestions'>
							{productData.possibleQuestions_Questions.length ? (
								productData.possibleQuestions_Questions.map((question, index) => (
									<div key={index}>
										<label htmlFor='possibleQuestions_Questions'>Question:</label>
										<input
											id='possibleQuestions_Questions'
											name='possibleQuestions_Questions'
											type='text'
											value={question}
											onChange={handleDataArrayChange(index)}
										/>
										<label htmlFor='possibleQuestions_Answers'>Answer:</label>
										<input
											id='possibleQuestions_Answers'
											name='possibleQuestions_Answers'
											type='text'
											value={productData.possibleQuestions_Answers[index]}
											onChange={handleDataArrayChange(index)}
										/>
									</div>
								))
							) : (
								<div>
									<label htmlFor='possibleQuestions_Questions'>Question:</label>
									<input
										id='possibleQuestions_Questions'
										name='possibleQuestions_Questions'
										type='text'
										onChange={handleDataArrayChange(0)}
									/>
									<label htmlFor='possibleQuestions_Answers'>Answer:</label>
									<input
										id='possibleQuestions_Answers'
										name='possibleQuestions_Answers'
										type='text'
										onChange={handleDataArrayChange(0)}
									/>
								</div>
							)}

							<div id='addQuestions'></div>
							<div id='div-button' onClick={() => addInputField('possibleQuestions_Questions')}>
								Add_Questions
							</div>
						</div>
						<div></div>
						<div>
							<input
								id='sbm-btn'
								type='submit'
								name='submit'
								value={props.id ? 'Modify Product' : 'Add Product'}
							/>
						</div>
					</form>
				</Container>
			</div>
		);
	}
}

export default withRouter(Form);
