import React, { useState, useEffect } from 'react';
import Loading from '../loading_page/Loading';

import './FAQ.css';
import { Container, Table } from 'react-bootstrap';
// import { Link, Switch, Redirect, Route } from 'react-router-dom';

function FAQ() {
	const [FAQs, setFAQs] = useState([]);
	const [loading, setLoading] = useState(true);

	const getData = () => {
		return new Promise((res, rej) => {
			//console.log(`page33: ${page}`);
			//console.log(`perPAge34: ${perPage}`);
			fetch(`https://petro-store-server.herokuapp.com/api/FAQ/all`)
				//fetch(`http://127.0.0.1:8080/api/FAQ/all`)
				.then((res) => res.json())
				.then((data) => {
					if (data.length) {
						console.log(JSON.stringify(data));

						res(data.reverse());
					} else {
						res(false);
					}
				});
		});
	};

	//After the component has been "mounted", we must Call our "getData(page)..."
	useEffect(() => {
		getData().then((data) => {
			console.log(`page52: ${data}`);
			//console.log(`perPAge53: ${perPage}`);
			if (data) setFAQs(data);
			//console.log(`data.length: ${data}`);
			setLoading(false);
		});
	}, []);

	if (loading) {
		return <Loading></Loading>; // render nothing until the form data is loaded
	} else {
		if (FAQs.length > 0) {
			return (
				<div id='FAQ-page'>
					<hr></hr>
					<Container>
						<div id='FAQ-page-in-container'>
							<center>
								<h1>Frequently asked questions</h1>
							</center>
							<Table striped bordered hover id='FAQ-page-table'>
								<thead>
									<tr>
										<th className='FAQ-page-data-mobile-disable'>#</th>
										<th>Question</th>
										<th>Answer</th>
										<th className='FAQ-page-data-mobile-disable'>Posted</th>
										<th className='FAQ-page-data-mobile-disable'>Modified</th>
									</tr>
								</thead>
								<tbody>
									{FAQs.map((answer_question, index) => (
										<tr key={answer_question._id}>
											<td className='FAQ-page-data-mobile-disable'>{index + 1}</td>
											<td>{answer_question.question}</td>
											<td>{answer_question.answer}</td>
											<td className='FAQ-page-data-mobile-disable'>
												{answer_question.createdAt.substring(0, 10) +
													' ' +
													answer_question.createdAt.substring(11, 19)}
											</td>
											<td className='FAQ-page-data-mobile-disable'>
												{answer_question.updatedAt.substring(0, 10) +
													' ' +
													answer_question.updatedAt.substring(11, 19)}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</Container>
					<hr></hr>
				</div>
			);
		} else {
			return (
				<div>
					<center>
						<b>No data</b>
					</center>
				</div>
			);
		}
	}
}

export default FAQ;
