import "./AppHeader.css";

import logo from "./logo_foodlink.jpg";
import cart from "./components/userCart_page/cart.jpg";

import React, { useState, useEffect } from "react";
import {
  Nav,
  NavDropdown,
  FormGroup,
  FormControl,
  Container,
  Table,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

function AppHeader(props) {
  const [searchId, setSearchId] = useState("");

  const updateSearchId = (e) => {
    setSearchId(e.target.value.toUpperCase());
  };

  return (
    <div id="app-header">
      <Container>
        <div id="header-in-container-div">
          <Link id="header-logo" to={"/"}>
            <img src={logo} className="App-logo" alt="logo" />
          </Link>
          <NavDropdown title="Products" id="header-products-dropdown">
            <div id="products-dropdown-div">
              <div id="basic-nav-dropdown-all">
                <Nav title="All Products" id="basic-nav-dropdown">
                  <Link
                    className="btn"
                    id="main-dropdown-link"
                    to={"/Products/All/All/All/All"}
                  >
                    <b>All</b>
                  </Link>

                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/productType/Cup/All/All"}
                  >
                    Cups
                  </Link>
                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/productType/Bowl/All/All"}
                  >
                    Bowls
                  </Link>
                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/productType/Bucket/All/All"}
                  >
                    Buckets
                  </Link>
                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/productType/Bag/All/All"}
                  >
                    Bags
                  </Link>
                </Nav>
              </div>
              <div id="basic-nav-dropdown-paper">
                <Nav title="Paper" id="basic-nav-dropdown">
                  <Link
                    className="btn btn-default"
                    id="main-dropdown-link"
                    to={"/Products/material/Paper/All/All"}
                  >
                    <b>Paper</b>
                  </Link>

                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/material/Paper/productType/Cup"}
                  >
                    Cups
                  </Link>
                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/material/Paper/productType/Bowl"}
                  >
                    Bowls
                  </Link>
                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/material/Paper/productType/Bucket"}
                  >
                    Buckets
                  </Link>
                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/material/Paper/productType/Bag"}
                  >
                    Bags
                  </Link>
                </Nav>
              </div>
              <div id="basic-nav-dropdown-plastic">
                <Nav title="Plastic" id="basic-nav-dropdown">
                  <Link
                    className="btn btn-default"
                    id="main-dropdown-link"
                    to={"/Products/material/Plastic/All/All"}
                  >
                    <b>Plastic</b>
                  </Link>

                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/material/Plastic/productType/Cup"}
                  >
                    Cups
                  </Link>
                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/material/Plastic/productType/Bowl"}
                  >
                    Bowls
                  </Link>
                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/material/Plastic/productType/Bucket"}
                  >
                    Buckets
                  </Link>
                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/material/Plastic/productType/Bag"}
                  >
                    Bags
                  </Link>
                </Nav>
              </div>
              <div id="basic-nav-dropdown-other">
                <Nav title="Other" id="basic-nav-dropdown">
                  <Link
                    className="btn btn-default"
                    id="main-dropdown-link"
                    to={"/Products/material/Other/All/All"}
                  >
                    <b>Other</b>
                  </Link>

                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/material/Other/productType/Cup"}
                  >
                    Cups
                  </Link>
                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/material/Other/productType/Bowl"}
                  >
                    Bowls
                  </Link>
                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/material/Other/productType/Bucket"}
                  >
                    Buckets
                  </Link>
                  <Link
                    className="btn btn-default header-dropdown-link"
                    to={"/Products/material/Other/productType/Bag"}
                  >
                    Bags
                  </Link>
                </Nav>
              </div>
              <div
                id="header-dropdown-hover-img"
                className="btn-header-mobile-disable"
              ></div>
            </div>
          </NavDropdown>
          <Link
            className="btn-header btn-header-mobile-disable "
            to={"/Products/All/All/All/All"}
          >
            All Products
          </Link>
          <Link
            className="btn-header btn-header-mobile-disable"
            to={"/ContactUs"}
          >
            Contact Us
          </Link>
          {/* <Link className='btn-header btn-header-mobile-disable' to={'/FAQ'}>
						FAQ
					</Link> */}
          <div id="header-prev-view-search-div">
            <div id="header-search-div">
              <FormGroup>
                <FormControl
                  id="header-search-input"
                  type="text"
                  onChange={updateSearchId}
                  placeholder="SKU"
                />
              </FormGroup>{" "}
              <Link id="btn-search" to={"/Product/" + searchId}>
                <i class="fa fa-search"></i>
              </Link>
            </div>
            <NavDropdown title="Previously Viewed" id="prev-view-dropdown">
              {props.recentlyViewed.length > 0
                ? props.recentlyViewed.map((product, index) => (
                    // <Table key={index}>
                    // 	<tr>
                    // 		<Link className='recentlyViewed-link' to={`/Product/${product.id}`}>
                    // 			<td>
                    // 				<img
                    // 					className='recentlyViewed-image'
                    // 					src={`${process.env.PUBLIC_URL}/assets/products/SKU ${product.catNumber}/${product.image}`}
                    // 					alt='coming soon'
                    // 				/>
                    // 			</td>
                    // 			{/* <td>{product.name}</td> */}
                    // 			<td className='recentlyViewed-SKU'>{product.catNumber}</td>
                    // 		</Link>
                    // 	</tr>
                    // </Table>
                    <div key={index}>
                      <div className="recentlyViewed-product-div">
                        <div
                          className="header-remove-recentlyViewed-button"
                          style={{ width: "1.8em", height: "1.8em" }}
                          onClick={() => {
                            props.removeFromRecentlyViewed(index);
                          }}
                        >
                          x
                        </div>
                        <Link
                          className="recentlyViewed-link"
                          to={`/Product/${product.id}`}
                        >
                          <div>
                            <img
                              className="recentlyViewed-image"
                              src={`${process.env.PUBLIC_URL}/assets/products/SKU ${product.catNumber}/${product.image}`}
                              alt="coming soon"
                            />
                          </div>
                          {/* <td>{product.name}</td> */}
                          <div className="recentlyViewed-SKU">
                            {product.catNumber}
                          </div>
                        </Link>
                      </div>
                      <hr></hr>
                    </div>
                  ))
                : ""}
              {props.recentlyViewed.length > 0 && (
                <div
                  className="header-remove-recentlyViewed-button"
                  style={{ width: "5.5em", margin: "0 0.5vmin" }}
                  onClick={() => {
                    props.removeFromRecentlyViewed(-1); // setRecentlyViewed([]);
                  }}
                >
                  clear all
                </div>
              )}
            </NavDropdown>
          </div>

          <Link id="header-cart-link" to={"/UserCart"}>
            <img src={cart} id="user-cart-img" alt="cart" />

            <p>
              <span className="btn-header-mobile-disable"> Enquiry </span>Cart
              <span>({props.productsInCart})</span>
            </p>
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default AppHeader;
