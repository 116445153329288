import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Loading from '../loading_page/Loading';

import './Products.css';
import { Container, Button, Card, Pagination } from 'react-bootstrap';

function Products(props) {
	const [products, setProducts] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [totalProducts, setTotalProducts] = useState(0);
	const [loading, setLoading] = useState(true);

	const getData = (page, perPage) => {
		return new Promise((res, rej) => {
			fetch(
				`https://petro-store-server.herokuapp.com/api/products?page=${page}&perPage=${perPage}&field1=${props.field1}&filter1=${props.filter1}&field2=${props.field2}&filter2=${props.filter2}`
			)
				// fetch(
				// 	`http://127.0.0.1:8080/api/products?page=${page}&perPage=${perPage}&field1=${props.field1}&filter1=${props.filter1}&field2=${props.field2}&filter2=${props.filter2}`
				// )
				.then((res) => res.json())
				.then((data) => {
					setTotalProducts(data.totalProducts);

					if (data.data.length) {
						res(data.data);
					} else {
						res(false);
					}
				});
		});
	};

	//After the component has been "mounted", we must Call our "getData(page)..."
	useEffect(() => {
		getData(currentPage, perPage).then((data) => {
			if (data) {
				setProducts(data);
				setLoading(false);
			} else {
				setProducts([]);
				setLoading(false);
			}
		});
	}, [props.field1, props.filter1, props.field2, props.filter2, perPage, currentPage]);

	const handlePerPageChange = (e) => {
		if (e.target.value > 0) setPerPage(e.target.value);
		if (products.length > 0) setCurrentPage(1);
	};
	if (loading) {
		return <Loading></Loading>;
	} else {
		if (products.length > 0) {
			return (
				<div id='products-page'>
					<hr></hr>
					<Container>
						<div id='products-page-in-container'>
							<div id='before-products-div'>
								<h4>
									<span className='products-span-mobile-disable'> Total:</span> {totalProducts}{' '}
									{props.field1 === 'All'
										? 'products'
										: props.field2 === 'All'
										? props.filter1 + '(s)'
										: props.filter1 + ' ' + props.filter2 + '(s)'}
								</h4>

								<h5>
									per page:
									<span className='products-per-page-number'>
										<input
											id='productsPage-items-perPage-input'
											type='number'
											value={perPage}
											min='0'
											onChange={handlePerPageChange}
										/>
									</span>
									<span
										className='products-per-page-number'
										onClick={() => {
											setPerPage(15);
										}}
									>
										{15}
									</span>
									<span
										className='products-per-page-number'
										onClick={() => {
											setPerPage(20);
										}}
									>
										20
									</span>
									<span
										className='products-per-page-number'
										onClick={() => {
											setPerPage(30);
										}}
									>
										30
									</span>
									<span
										className='products-per-page-number'
										onClick={() => {
											setPerPage(totalProducts);
										}}
									>
										all
									</span>
								</h5>
								<div></div>
							</div>
							<div id='products-list'>
								{products.map((product) => (
									<Card key={product._id} bg='light' text='dark' className='products-card'>
										<Link to={`/Product/${product._id}`}>
											<Card.Img
												variant='top'
												src={`${process.env.PUBLIC_URL}/assets/products/SKU ${product.catNumber}/${product.images[0]}`}
											/>
										</Link>
										<Card.Body>
											<Link className='products-card-body-link' to={`/Product/${product._id}`}>
												<Card.Title>{product.name}</Card.Title>
												{/* <Card.Subtitle>
												Material: {product.material}
												<br></br> SKU: {product.catNumber}
											</Card.Subtitle> */}
												{/* <Card.Subtitle>
												<hr></hr>
												Details...
											</Card.Subtitle> */}
											</Link>
										</Card.Body>
										<Button
											small
											id='productsPage-addToEnquiry-button'
											onClick={() => {
												let tempProduct = {};
												tempProduct.image = product.images[0];
												tempProduct.id = product._id;
												tempProduct.name = product.name;
												tempProduct.material = product.material;
												tempProduct.SKU = product.catNumber;
												tempProduct.quantity = 1;
												tempProduct.message = '';
												props.addToCart(tempProduct);
												// props.history.push(`/UserCart`);
											}}
											variant='success'
										>
											Add to Enquiry
										</Button>
									</Card>
								))}
							</div>
							<br></br>
							<Pagination>
								<Pagination.Prev
									id='productsPage-pagination-prev'
									onClick={() => {
										if (currentPage !== 1) setCurrentPage(currentPage - 1);
									}}
								/>
								{currentPage > 4 ? (
									<Pagination.Item
										onClick={() => {
											setCurrentPage(1);
										}}
									>
										<span className='productsPage-pagination-number'>{1}</span>
									</Pagination.Item>
								) : (
									''
								)}
								{currentPage > 5 ? (
									currentPage === 6 ? (
										<Pagination.Item
											onClick={() => {
												setCurrentPage(2);
											}}
										>
											<span className='productsPage-pagination-number'>{2}</span>
										</Pagination.Item>
									) : (
										<Pagination.Item disabled>...</Pagination.Item>
									)
								) : (
									''
								)}

								{currentPage > 3 ? (
									<Pagination.Item
										onClick={() => {
											setCurrentPage(currentPage - 3);
										}}
									>
										<span className='productsPage-pagination-number'>{currentPage - 3}</span>
									</Pagination.Item>
								) : (
									''
								)}
								{currentPage > 2 ? (
									<Pagination.Item
										onClick={() => {
											setCurrentPage(currentPage - 2);
										}}
									>
										<span className='productsPage-pagination-number'>{currentPage - 2}</span>
									</Pagination.Item>
								) : (
									''
								)}
								{currentPage > 1 ? (
									<Pagination.Item
										onClick={() => {
											setCurrentPage(currentPage - 1);
										}}
									>
										<span className='productsPage-pagination-number'>{currentPage - 1}</span>
									</Pagination.Item>
								) : (
									''
								)}

								<Pagination.Item active id='products-page-pagination-current-page'>
									{currentPage}
								</Pagination.Item>
								{totalProducts > perPage * currentPage ? (
									<Pagination.Item
										onClick={() => {
											setCurrentPage(currentPage + 1);
										}}
									>
										<span className='productsPage-pagination-number'>{currentPage + 1}</span>
									</Pagination.Item>
								) : (
									''
								)}
								{totalProducts > perPage * (currentPage + 1) ? (
									<Pagination.Item
										onClick={() => {
											setCurrentPage(currentPage + 2);
										}}
									>
										<span className='productsPage-pagination-number'>{currentPage + 2}</span>
									</Pagination.Item>
								) : (
									''
								)}
								{totalProducts > perPage * (currentPage + 2) ? (
									<Pagination.Item
										onClick={() => {
											setCurrentPage(currentPage + 3);
										}}
									>
										<span className='productsPage-pagination-number'>{currentPage + 3}</span>
									</Pagination.Item>
								) : (
									''
								)}
								{totalProducts / perPage > currentPage + 3 ? (
									totalProducts / perPage < currentPage + 4 ? (
										<Pagination.Item
											onClick={() => {
												setCurrentPage(currentPage + 4);
											}}
										>
											<span className='productsPage-pagination-number'>{currentPage + 4}</span>
										</Pagination.Item>
									) : Math.floor(totalProducts / perPage) + 1 === currentPage + 5 ? (
										<Pagination.Item
											onClick={() => {
												setCurrentPage(currentPage + 4);
											}}
										>
											<span className='productsPage-pagination-number'>{currentPage + 4}</span>
										</Pagination.Item>
									) : (
										<Pagination.Item disabled>...</Pagination.Item>
									)
								) : (
									''
								)}
								{totalProducts > perPage * (currentPage + 4) ? (
									<Pagination.Item
										onClick={() => {
											setCurrentPage(
												Number.isInteger(totalProducts / perPage)
													? totalProducts / perPage
													: Math.floor(totalProducts / perPage) + 1
											);
										}}
									>
										<span className='productsPage-pagination-number'>
											{Number.isInteger(totalProducts / perPage)
												? totalProducts / perPage
												: Math.floor(totalProducts / perPage) + 1}
										</span>
									</Pagination.Item>
								) : (
									''
								)}

								<Pagination.Next
									id='productsPage-pagination-next'
									onClick={() => {
										if (totalProducts > perPage * currentPage) setCurrentPage(currentPage + 1);
									}}
								/>
							</Pagination>
						</div>
					</Container>
					<hr></hr>
				</div>
			);
		} else {
			return (
				<Container>
					<div className='products-page'>
						<h1>There is no such products in database yet</h1>
					</div>
				</Container>
			);
		}
	}
}

export default withRouter(Products);
